import * as React from "react";
import { Link } from "gatsby";

// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function SundayMorning() {
  return (
    <Layout>
      <Helmet title="Sunday Morning | Maroon 5" />

      <h1>Sunday Morning</h1>

      <h2>Maroon 5</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>Sunday morning, rain is falling</p>
          <p>Steal some covers, share some skin</p>
          <p>Clouds are shrouding us in moments unforgettable</p>
          <p>You twist to fit the mold that I am in</p>
        </Verse>
        <Verse>
          <p>But things just get so crazy</p>
          <p>Living life gets hard to do</p>
          <p>And I would gladly hit the road, get up and go if I knew</p>
          <p>That someday it would lead me back to you</p>
          <p>That someday it would lead me back to you</p>
        </Verse>
        <Chorus>
          <p>That may be all I need</p>
          <p>In darkness she is all I see</p>
          <p>Come and rest your bones with me</p>
          <p>Driving slow on Sunday morning</p>
          <p>Well I never want to leave</p>
        </Chorus>
        <Verse>
          <p>Yeah, fingers trace your every outline, oh yeah, yeah</p>
          <p>Yeah, ain't a picture with my hands, ohh!</p>
          <p>And back and forth we sway like branches in a storm</p>
          <p>Change the weather still together when it ends</p>
        </Verse>
        <Chorus>
          <p>That may be all I need</p>
          <p>In darkness she is all I see</p>
          <p>Come and rest your bones with me</p>
          <p>Driving slow on Sunday morning</p>
          <p>Well I never want to leave</p>
        </Chorus>
        <Verse>
          <p>But things just get so crazy living</p>
          <p>Life gets hard to do (life gets hard)</p>
          <p>Sunday morning rain is falling and I'm calling out to you</p>
          <p>Singing someday it'll bring me back to you,</p>
          <p>Yeah (Someday oh, someday oh)</p>
          <p>Find a way to bring myself back home to you</p>
        </Verse>
        <Chorus>
          <p>That may be all I need</p>
          <p>In darkness she is all I see</p>
          <p>Come and rest your bones with me</p>
          <p>Driving slow on Sunday morning</p>
          <p>Well I never want to leave</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
